import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import ApiStatusView from "../views/ApiStatusView.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            title: "Home",
            requiresAuth: false
        }
    },
    {
        path: "/api-status",
        name: "api-status",
        component: ApiStatusView,
        meta: {
            title: "API Status",
            requiresAuth: false
        }
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import(/* webpackChunkName: "settings" */ "../views/SettingsView.vue"),
        meta: {
            title: "Settings",
            requiresAuth: true,
            permissions: ["admin", "can-edit-settings"]
        }
    },
    {
        path: "/distribution-np",
        name: "distribution-np",
        component: () => import(/* webpackChunkName: "distributions" */ "../views/DistributionsView.vue"),
        meta: {
            title: "Distributions",
            requiresAuth: true,
            permissions: ["admin", "can-edit-distributions"]
        }
    },
    {
        path: "/custom-urls",
        name: "custom-urls",
        component: () => import(/* webpackChunkName: "custom-urls" */ "../views/CustomURLsView.vue"),
        meta: {
            title: "Custom URLs",
            requiresAuth: true,
            permissions: ["admin", "can-edit-custom-urls"]
        }
    },
    {
        path: "/reports",
        name: "reports",
        component: () => import(/* webpackChunkName: "custom-urls" */ "../views/ReportsView.vue"),
        meta: {
            title: "Reports",
            requiresAuth: true,
            permissions: ["admin", "can-view-reports"]
        }
    },
    {
        path: "/users",
        name: "users",
        component: () => import(/* webpackChunkName: "custom-urls" */ "../views/UsersView.vue"),
        meta: {
            title: "Users",
            requiresAuth: true,
            permissions: ["sysadmin", "can-edit-users"]
        }
    },
    {
        path: "/sandbox",
        name: "sandbox",
        component: () => import(/* webpackChunkName: "custom-urls" */ "../views/SandboxView.vue"),
        meta: {
            title: "Sandbox",
            requiresAuth: true,
            permissions: ["sysadmin"]
        }
    },
    {
        path: "/reset-password/:code",
        name: "reset-password",
        component: () => import(/* webpackChunkName: "custom-urls" */ "../views/ResetPasswordView.vue"),
        meta: {
            title: "Reset Password",
            requiresAuth: false,
        }
    },
    {
        path: "/logout",
        name: "logout",
        meta: {
            title: "Log out",
            requiresAuth: true
        },
        beforeEnter: (_to, _from, next) => {
            store.dispatch("logout").then(() => {
                return next("/")
            })
        },
    }
];

const router = new VueRouter({ mode: "history", base: __dirname, routes });

router.beforeEach((to, _from, next) => {
    // console.log(`router.beforeEach.to`, to);
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            if(!to.meta.permissions || !to.meta.permissions.length) {
                next();
            }
            else {
                const userPermissions = store.state.auth.user.permissions;
                let canAccess = false;
                if(userPermissions.includes("sysadmin")) canAccess = true;
                to.meta.permissions.forEach(routePermission => {
                    if(userPermissions.includes(routePermission)) canAccess = true;
                });
                if(canAccess) {
                    next();
                }
                else {
                    next("/");
                }
            }
        } else {
            next("/");
        }
    } else {
        next();
    }
});

export default router;
